/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, List, Text, ImageSet } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-wbyw0t css-74yk58 js-anim  --anim7 --anim-s5 --full" anim={"7"} name={"uvod"} animS={"5"} border={null} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/b95753b5c8034b63922b7f93296ddc72_s=3000x_.jpg);
    }
  
background-position: 59% 15%;
    `}>
          
          <ColumnWrap className="column__flex --center el--2 pt--10 flex--center" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pt--12" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--102 w--300 lh--12" content={"<span style=\"color: white\">Егор &amp; Валерия</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style=\"color: white\">23—03—23, Savoia Castle, Škvorec</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"mestoivremya"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--05)"}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--43 w--300" style={{"maxWidth":615}} content={"<span style=\"color: var(--color-custom-2--15);\">Место и время</span>"}>
              </Title>

              <Title className="title-box ff--2 fs--24" content={"<span style=\"color: var(--color-custom-2--15);\">14:00</span><br>"}>
              </Title>

              <Title className="title-box ff--2 fs--24" content={"<span style=\"color: var(--color-custom-2--15);\">Savoia Castle</span>"}>
              </Title>

              <Title className="title-box ff--2 fs--24" content={"<span style=\"color: var(--color-custom-2--15);\">Smiřických 1, 250 83</span>"}>
              </Title>

              <Title className="title-box ff--2 fs--24" content={"<span style=\"color: var(--color-custom-2--15);\">Škvorec</span>"}>
              </Title>

              <Subtitle className="subtitle-box ff--2 fs--24" content={"<span style=\"color: var(--color-custom-2--95);\"><a href=\"https://goo.gl/maps/h237aF2h9eY8WQmx5\" target=\"_blank\">Место на карте</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32995/a26be93c8ec7427d8623f0b341381550_e=2x4x1010x1346_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/32995/a26be93c8ec7427d8623f0b341381550_e=2x4x1010x1346_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32995/a26be93c8ec7427d8623f0b341381550_e=2x4x1010x1346_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32995/a26be93c8ec7427d8623f0b341381550_e=2x4x1010x1346_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--25" name={"programma"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32995/f586ef6b5a1340acb814511310cd31ab_e=113x25x1308x1960_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32995/f586ef6b5a1340acb814511310cd31ab_e=113x25x1308x1960_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32995/f586ef6b5a1340acb814511310cd31ab_e=113x25x1308x1960_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32995/f586ef6b5a1340acb814511310cd31ab_e=113x25x1308x1960_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--43 w--300" content={"Программа дня"}>
              </Title>

              <List className="text-box text-box--left ff--1 fs--24 ls--0" content={"<li><span style=\"color: var(--color-blend--05);\">14:00 - Welcome drink и канапе<br></span></li><li><span style=\"color: var(--color-blend--05);\">14:30 - Церемония бракосочетания</span></li><li><span style=\"color: var(--color-blend--05);\">15:30 - Cocktail hour<br></span></li><li><span style=\"color: var(--color-blend--05);\">17:00 - Свадебный ужин<br></span></li><li><span style=\"color: var(--color-blend--05);\">19:00 - Танец молодоженов с родителями</span></li><li><span style=\"color: var(--color-blend--05);\">21:00 - Церемония бросания букета</span></li><li><span style=\"color: var(--color-blend--05);\">21:20 - Разрезание торта</span></li><li><span style=\"color: var(--color-blend--05);\">21:50 - Первый танец молодоженов</span></li><li><span style=\"color: var(--color-blend--05);\">22:00 - Дискотека</span></li>"} subtype={"unordered"}>
              </List>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1leyun8 css-42e4bw --center --parallax pt--80" name={"foto"} style={{"paddingBottom":333}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/ed871d6c274c4eeb820be34eb6265801_bri=85__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dresscode"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"backgroundColor":"var(--color-blend--05)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2--15);\">Дресс-Код</span>"}>
              </Title>

              <Text className="text-box ff--2 fs--22" style={{"maxWidth":1000}} content={"<span style=\"color: var(--color-custom-2--15);\">Дорогие гости, мы попытались сделать нашу свадьбу как можно более красивым и запоминающимся событием нашей жизни, поэтому мы были бы очень благодарны, если бы Вы смогли придерживаться черно-белых оттенков в своих костюмах и платьях. Вы можете найти пару примеров для вдохновения перейдя по <a href=\"https://www.instagram.com/reel/Ckgp1dzjmsg/?igshid=ODk2MDJkZDc2Zg==\">ссылке</a>.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ImageSet images={[{"src":"https://cdn.swbpg.com/t/32995/2d5758265c824a76b897c32251a67689_.jpg","uuid":"32adc70479d24dee9e40f58c734ab8f2","position":{"top":"10%","left":"10%","width":"34.08%"}},{"src":"https://cdn.swbpg.com/o/32995/26d8713273db498e8c08a7872275d99f.jpg","uuid":"26d8713273db498e8c08a7872275d99f","position":{"top":"-18.83%","left":"43.12%","width":"30%"}}]}>
              </ImageSet>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"mpwga3r69xe"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"maxWidth":334}} content={"<span style=\"color: var(--color-blend--05);\">По любым организационным вопросам Вы можете обратиться к нашему свадебному координатору.</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Елизавета Вольхина"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--05);\">+420 775 314 669<br>info@brilliant-wedding.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1bj4ss css-3gk30r --center pb--80 pt--80" name={"informace-5"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/777f382a3ec3489090c462fcf238ef29_bri=85__s=3000x_.jpg);
    }
  
background-position: 53% 63%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--80">
              
              <Title className="title-box title-box--center fs--86" content={"<span style=\"color: white\">Ждем Вас с нетерпением!</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center" content={"<span style=\"color: white\">23.09.23</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mb--80 mt--40" style={{"maxWidth":900}} columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}